import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { viewports, responsiveSizes } from 'helpers/settings'
import styled from 'styled-components'
import intl from 'react-intl-universal'
import { ImageContainer } from 'components/base'
import rafael from 'assets/team/rafael.jpeg'
import mauro from 'assets/team/mauro.jpeg'
import stefano from 'assets/team/stefano.jpeg'
import edna from 'assets/team/edna.jpg'
import eduardo from 'assets/team/eduardo.jpeg'
import mario from 'assets/team/mario.jpeg'
import luis from 'assets/team/luis.jpeg'
import andre from 'assets/team/andre.jpeg'


const Container = styled.div`
  display: flex;
  height: 3400px;
  width: 1345px;
  flex-direction: column;
  overflow: hidden;
  ${viewports.small} {
    align-items: center;
    padding: ${responsiveSizes.sidePagePadding};
    height: 100%;
    width: 340px;
  }
`
const Title = styled.label`
  ${props => !props.alignCenter && 'text-align: left'}};
  color: #000000;
  font-family: Josefin Sans;
  font-size: 40px;
  margin: 60px;
  text-transform: capitalize;
  ${viewports.small} {
    font-size: 23px;
    margin-top: 110px;
    text-align: center;
  }
`
const Content = styled(ImageContainer)`
  width: 100%;
  height: 100%;
  background-position: top;
  background-size: 90%;
  ${viewports.small} {
    background-size: 0;
  }
`
const RowContainer = styled.div`
  display: flex;
  padding-left: 190px;
  padding-top: 190px;
  margin-bottom: 48px;
  height: ${props => props.height}
  ${viewports.small} {
    flex-direction: column;
    padding: 0;
    margin: 0;
    height: auto
  }
`
const MemberCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 375px;
  position: relative;
  margin-left: ${props => props.leftMargin && '280px'};
  ${viewports.small} {
    margin-left: 5px;
    margin-right: 5px;
    align-items: center;
    margin-bottom: 40px;
    width: 320px;
    overflow: hidden;
    border: 6px solid #ddd;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`
const MemberName = styled.label`
  font-family: Josefin Sans;
  font-weight: bold;
  font-size: 19px;
  text-transform: capitalize;
  color: #1F98FF
`
const MemberPosition = styled.label`
  font-family: Josefin Sans;
  font-weight: 600px;
  font-size: 16px;
  font-style: italic;
  text-transform: capitalize;
  color: #606060;
`
const MemberAbout = styled.label`
  font-family: Lato;
  font-weight: 500px;
  font-size: 16px;
  color: #696969;
  margin-top: 10px;
  ${viewports.small} {
    text-align: justify;
    width: 90%;
  }
`
const Avatar = styled(ImageContainer)`
  height: 150px;
  width: 150px;
  border-radius: 100%;
  top: -150px;
  left: 260px;
  position: absolute;
  ${props => props.backgroundPosition && `background-position: ${props.backgroundPosition}`};
  background-size: ${props => props.backgroundSize ? props.backgroundSize : '110%'};
  border: 6px solid #B5B5B5;
  ${viewports.small} {
    height: 100px;
    width: 100px;
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 10px;
  }
`
class Team extends Component {
  render() {
    const teamMemberTranslator = 'team.members'
    return (

      <Container>
        <Title>{intl.get('team.title')}</Title>
        <Content
          path='team/background'
          without2x
        >
          <RowContainer height='237px'>
            <MemberCardContainer>
              <Avatar path='team/marcos'/>
              <MemberName>{intl.get(`${teamMemberTranslator}.marcos.name`)}</MemberName>
              <MemberPosition>{intl.get(`${teamMemberTranslator}.marcos.position`)}</MemberPosition>
              <MemberAbout>{intl.get(`${teamMemberTranslator}.marcos.about`)}</MemberAbout>
            </MemberCardContainer>
            <MemberCardContainer leftMargin>
              <Avatar
                image={andre}
                backgroundSize='108%'
                backgroundPosition='top'
              />
              <MemberName>{intl.get(`${teamMemberTranslator}.andre.name`)}</MemberName>
              <MemberPosition>{intl.get(`${teamMemberTranslator}.andre.position`)}</MemberPosition>
              <MemberAbout>{intl.get(`${teamMemberTranslator}.andre.about`)}</MemberAbout>
            </MemberCardContainer>
          </RowContainer>
          <RowContainer height='189px'>
            <MemberCardContainer>
              <Avatar path='team/gabriel'/>
              <MemberName>{intl.get(`${teamMemberTranslator}.gabriel.name`)}</MemberName>
              <MemberPosition>{intl.get(`${teamMemberTranslator}.gabriel.position`)}</MemberPosition>
              <MemberAbout>{intl.get(`${teamMemberTranslator}.gabriel.about`)}</MemberAbout>
            </MemberCardContainer>
            <MemberCardContainer leftMargin>
              <Avatar path='team/priscilla'/>
              <MemberName>{intl.get(`${teamMemberTranslator}.priscilla.name`)}</MemberName>
              <MemberPosition>{intl.get(`${teamMemberTranslator}.priscilla.position`)}</MemberPosition>
              <MemberAbout>{intl.get(`${teamMemberTranslator}.priscilla.about`)}</MemberAbout>
            </MemberCardContainer>
          </RowContainer>
          <RowContainer height='253px'>
            <MemberCardContainer>
              <Avatar path='team/luiz'/>
              <MemberName>{intl.get(`${teamMemberTranslator}.luiz.name`)}</MemberName>
              <MemberPosition>{intl.get(`${teamMemberTranslator}.luiz.position`)}</MemberPosition>
              <MemberAbout>{intl.get(`${teamMemberTranslator}.luiz.about`)}</MemberAbout>
            </MemberCardContainer>
            <MemberCardContainer leftMargin>
              <Avatar image={rafael}/>
              <MemberName>{intl.get(`${teamMemberTranslator}.rafael.name`)}</MemberName>
              <MemberPosition>{intl.get(`${teamMemberTranslator}.rafael.position`)}</MemberPosition>
              <MemberAbout>{intl.get(`${teamMemberTranslator}.rafael.about`)}</MemberAbout>
            </MemberCardContainer>
          </RowContainer>
          <RowContainer height='190px'>
            <MemberCardContainer>
              <Avatar image={edna}/>
              <MemberName>{intl.get(`${teamMemberTranslator}.edna.name`)}</MemberName>
              <MemberPosition>{intl.get(`${teamMemberTranslator}.edna.position`)}</MemberPosition>
              <MemberAbout>{intl.get(`${teamMemberTranslator}.edna.about`)}</MemberAbout>
            </MemberCardContainer>
            <MemberCardContainer leftMargin>
              <Avatar image={stefano}/>
              <MemberName>{intl.get(`${teamMemberTranslator}.stefano.name`)}</MemberName>
              <MemberPosition>{intl.get(`${teamMemberTranslator}.stefano.position`)}</MemberPosition>
              <MemberAbout>{intl.get(`${teamMemberTranslator}.stefano.about`)}</MemberAbout>
            </MemberCardContainer>
          </RowContainer>
          <RowContainer height='349px'>
            <MemberCardContainer>
              <Avatar path='team/marco'/>
              <MemberName>{intl.get(`${teamMemberTranslator}.marco.name`)}</MemberName>
              <MemberPosition>{intl.get(`${teamMemberTranslator}.marco.position`)}</MemberPosition>
              <MemberAbout>{intl.get(`${teamMemberTranslator}.marco.about`)}</MemberAbout>
            </MemberCardContainer>
            <MemberCardContainer leftMargin>
              <Avatar image={mauro}/>
              <MemberName>{intl.get(`${teamMemberTranslator}.mauro.name`)}</MemberName>
              <MemberPosition>{intl.get(`${teamMemberTranslator}.mauro.position`)}</MemberPosition>
              <MemberAbout>{intl.get(`${teamMemberTranslator}.mauro.about`)}</MemberAbout>
            </MemberCardContainer>
          </RowContainer>
          <RowContainer height='245px'>
            <MemberCardContainer>
              <Avatar
                image={eduardo}
                backgroundSize='123%'
              />
              <MemberName>{intl.get(`${teamMemberTranslator}.eduardo.name`)}</MemberName>
              <MemberPosition>{intl.get(`${teamMemberTranslator}.eduardo.position`)}</MemberPosition>
              <MemberAbout>{intl.get(`${teamMemberTranslator}.eduardo.about`)}</MemberAbout>
            </MemberCardContainer>
            <MemberCardContainer leftMargin>
              <Avatar image={mario}/>
              <MemberName>{intl.get(`${teamMemberTranslator}.mario.name`)}</MemberName>
              <MemberPosition>{intl.get(`${teamMemberTranslator}.mario.position`)}</MemberPosition>
              <MemberAbout>{intl.get(`${teamMemberTranslator}.mario.about`)}</MemberAbout>
            </MemberCardContainer>
          </RowContainer>
          <RowContainer height='250px'>
            <MemberCardContainer>
              <Avatar
                image={luis}
                backgroundSize='100%'
                backgroundPosition='top'
              />
              <MemberName>{intl.get(`${teamMemberTranslator}.luis.name`)}</MemberName>
              <MemberPosition>{intl.get(`${teamMemberTranslator}.luis.position`)}</MemberPosition>
              <MemberAbout>{intl.get(`${teamMemberTranslator}.luis.about`)}</MemberAbout>
            </MemberCardContainer>
          </RowContainer>
        </Content>
      </Container>
    )
  }
}


export default withRouter(Team)