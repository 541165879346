import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { TopCollapsed, StartupsContactButton, VideoPlayer, PortfolioContent } from 'components'
import { ImageContainer } from 'components/base'
import styled from 'styled-components'
import intl from 'react-intl-universal'
import { viewports, responsiveSizes } from 'helpers/settings'
import playVideo from 'assets/home/who-we-are/video-player.png'
import videoPosterGif from 'assets/home/who-we-are/video-poster.gif'
import { getLanguage } from 'helpers/languageHelper'

const WhoWeAreContainer = styled(ImageContainer)`
  display: flex;
  height: 700px;
  background-size: cover;
  ${viewports.small} {
    height: 617px;
  }
`
const WhoWeAreContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 3, 38, .6);
  ${viewports.small} {
    flex-direction: column;
    padding-left: ${responsiveSizes.sidePagePadding};
    padding-right: ${responsiveSizes.sidePagePadding};
    overflow: hidden;
  }
`
const WhoWeAreLeftSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50%;
  ${viewports.small} {
    width: 100%;
    height: 50%;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 30px;
  }
`
const WhoWeAreRightSide = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding-right: 100px;
  ${viewports.medium} {
    padding-right: 20px;
  }
  ${viewports.small} {
    width: 100%;
    height: 50%;
    justify-content: flex-start;
  }
`
const WhoWeAreTitle = styled.label`
  font-size: 46px;
  font-family: Josefin Sans;
  font-weight: bold;
  color: #1F98FF;
  height: 55px;
  width: 100%;
  text-transform: capitalize;
  ${viewports.medium} {
    margin-top: 100px;
  }
  ${viewports.small} {
    text-align: center;
    font-size: 38px;
    width: 300px;
    height: auto;
    margin-bottom: 10px;
  }

`
const WhoWeAreDescription = styled.label`
  font-size: 24px;
  font-family: Lato;
  color: #FFFFFF;
  ${viewports.small} {
    text-align: justify;
    font-size: 18px;
    width: 300px;
  }
`
const PlayVideoButton = styled.img`
  cursor: pointer;
  ${viewports.small} {
    width: 120px;
    height: 120px;
  }
`

const ValidationThesisContainer = styled(ImageContainer)`
  width: 100%;
  height: 700px;
  background-size: 102% 102%;
  ${viewports.small}   {
    height: 215px;
  }
`

const OurSectionContainer = styled(ImageContainer)`
  display: flex;
  flex-direction: column;
  height: auto;
  background-size: 100% 950px;
  background-position: top;
  overflow: hidden;
  ${viewports.small} {
    background-size: cover;
  }
`
const OurVisionContent = styled.div`
  display: flex;
  overflow: hidden;
  padding-bottom: 30px;
  ${viewports.small} {
    flex-direction: column;
    width: 100%;
  }
`
const OurSectionLeftSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 1160px;
  height: 100%;
  padding-left: 83px;
  ${viewports.small} {
    padding: 0;
    width: 100%;
    align-items: center;
  }

`
const OurSectionRightSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 2300px;
  padding-right: 20px;
  ${viewports.small} {
    padding: 0;
    width: 100%;
    align-items: center;
  }

`
const OurVisionTitle = styled.label`
  font-size: 40px;
  font-family: Josefin Sans;
  font-weight: bold;
  color: #1F98FF;
  height: 55px;
  text-transform: capitalize;
  margin-top: 60px;
  ${viewports.small} {
    text-align: center;
    font-size: 38px;
    width: 100%;
    height: auto;
  }
`
const WhatIsYourEverestLabel = styled.label`
  font-size: 30px;
  font-family: Lato;
  color: #FFFFFF;
  width: 186px;
  margin-top: 30px;
  border-bottom: 2px solid #FFFFFF;
  padding-bottom: 20px;
  ${viewports.small} {
    width: 280px;
    text-align: center;
    padding-bottom: 10px;
    font-size: 28px;
  }
`
const OurVisionDefaultParagraph = styled.label`
  font-family: Lato;
  font-size: 24px;
  color: #FFFFFF;
  margin-top: ${props => props.marginTop && `${props.marginTop}px`};
  ${viewports.small} {
    margin-top: 15px;
    text-align: justify;
    font-size: 23px;
    width: 300px;
  }

`
const OurVisionSecondParagraphContainer = styled.section`
  padding-top: 25px;
  padding-bottom: 25px;
  ${viewports.small} {
    text-align: justify;
    padding-bottom: 30px;
    font-size: 23px;
    width: 300px;
  }

`
const OurVisionSecondParagraph = styled.label`
  font-family: Lato;
  font-size: 24px;
  letter-spacing: 2px;
  line-height: 35px;
  color: ${props => props.highlighted ? '#1F98FF' : '#FFFFFF'};
  ${viewports.small} {
    font-size: 23px;
    width: 300px;
  }
`
const OurPurposeContent = styled.div`
  display: flex;
  ${viewports.small} {
    flex-direction: column;
  }
`
const OurPurposeTitle = styled.label`
  font-size: 38px;
  font-family: Josefin Sans;
  font-weight: bold;
  color: #1F98FF;
  text-transform: capitalize;
  margin-top: 150px;
  ${viewports.medium} {
    font-size: 35px;
  }
  ${viewports.small} {
    margin-top: 60px;
    width: 100%;
    font-size: 38px;
    text-align: center
  }
`
const OurPurposeFirstParagraph = styled.label`
  font-family: Lato;
  font-size: 24px;
  color: #FFFFFF;
  margin-top: 70px;
  ${viewports.small} {
    text-align: justify;
    width: 300px;
    font-size: 18;
  }
`
const OurPurposeSecondParagraph = styled.label`
  font-family: Lato;
  font-size: 24px;
  letter-spacing: 2px;
  color: #1F98FF;
  margin-top: 40px;
  margin-bottom: 40px;
  ${viewports.small} {
    text-align: justify;
    font-size: 20px;
    width: 300px;
  }

`
const OurPurposeThirdParagraph = styled.label`
  font-family: Lato;
  font-size: 24px;
  color: #FFFFFF;
  margin-bottom: 30px;
  ${viewports.small} {
    text-align: justify;
    width: 300px;
  }

`
const WhereWeAreContainer = styled(ImageContainer)`
  display: flex;
  height: 360px;
  overflow: hidden;
  background-size: 100% 610px;
  padding-bottom: 50px;
  padding-left: 185px;
  padding-top: 200px;
  flex-direction: column;
  ${viewports.small} {
    padding-top: 60px;
    padding-left: 0;
    padding-left: 0;
    align-items: center;
    height: auto;
    background-size: 190% 364px;
    background-position: -300px;
  }
`
const WhereWeAreTitle = styled.label`
  font-family: Josefin Sans;
  font-size: 40px;
  font-weight: bold;
  color: #FFFFFF;
  ${viewports.small} {
    text-align: center;
    width: 100%;
    font-size: 34px;
  }
`
const WhereWeAreSubtitle = styled.label`
  font-family: Josefin Sans;
  font-size: 24px;
  color: #FFFFFF;
  margin-top: 20px;
  width: 520px;
  ${viewports.small} {
    text-align: justify;
    font-size: 25px;
    width: 300px;
  }
`
const ContactUsContainer = styled(ImageContainer)`
  display: flex;
  height: 480px;
  background-size: 80%;
  background-size: cover;
  overflow: hidden;
  background-position: top;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${viewports.small} {
    padding-left: ${responsiveSizes.sidePagePadding};
    padding-left: ${responsiveSizes.sidePagePadding};
  }
`

const ContactUsTitle = styled.label`
  font-family: Josefin Sans;
  font-size: 42px;
  ${props => props.underline && 'border-bottom: 3px solid #FFFFFF'};
  color: #FFFFFF;
  text-align: center;
  margin-top: 20px;
  ${viewports.small} {
    font-size: 25px;
    width: 100%;
  }
`
const ContactUsSubtitle = styled.label`
  font-family: Lato;
  font-size: 35px;
  color: #FFFFFF;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  ${viewports.small} {
    font-size: 25px;
    width: 100%;
  }
`
const ContactUsButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  justify-content: center;
`
const PortfolioContainer = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
`
class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openVideo: false
    }
  }

  toggleVideo = () => {
    const { openVideo } = this.state
    this.setState({ openVideo: !openVideo })
  }

  render() {
    const { openVideo } = this.state
    const language = getLanguage()

    return (
      <div>
        <TopCollapsed/>
        <section id='who-we-are'>
          <WhoWeAreContainer
            image={videoPosterGif}
          >
            <WhoWeAreContent>
              <WhoWeAreLeftSide>
                <PlayVideoButton
                  src={playVideo}
                  onClick={() => this.toggleVideo()}
                />
              </WhoWeAreLeftSide>
              <WhoWeAreRightSide>
                <WhoWeAreTitle>{intl.get('home.who_we_are.title')}</WhoWeAreTitle>
                <WhoWeAreDescription>{intl.get('home.who_we_are.description')}</WhoWeAreDescription>
              </WhoWeAreRightSide>
            </WhoWeAreContent>
          </WhoWeAreContainer>
        </section>
        {
          language !== 'zh-hk' &&
          <section id='investment-thesis'>
            <ValidationThesisContainer
              path={`home/thesis/${language === 'pt-br' ? 'pt' : 'en'}`}
              imageExtension='jpeg'
              without2x
            />
            <ValidationThesisContainer
              path={`home/investment-thesis/${language === 'pt-br' ? 'pt' : 'en'}`}
              without2x
            />
            <ValidationThesisContainer
              path={`home/validation-thesis/${language === 'pt-br' ? 'pt' : 'en'}`}
              imageExtension='jpeg'
              without2x
            />
          </section>
        }
        <OurSectionContainer
          path='home/our-section'
          imageExtension='jpg'
          without2x
        >
          <section id='our-vision'>
            <OurVisionContent>
              <OurSectionLeftSideContainer>
                <OurVisionTitle>{intl.get('home.our_vision.title')}</OurVisionTitle>
                <WhatIsYourEverestLabel>{intl.get('home.our_vision.what_is_your_everest')}</WhatIsYourEverestLabel>
              </OurSectionLeftSideContainer>
              <OurSectionRightSideContainer>
                <OurVisionDefaultParagraph marginTop={148}>{intl.get('home.our_vision.description.paragraphs.first')}</OurVisionDefaultParagraph>
                <OurVisionSecondParagraphContainer>
                  <OurVisionSecondParagraph>{intl.get('home.our_vision.description.paragraphs.second.sections.first')}</OurVisionSecondParagraph>
                  <OurVisionSecondParagraph highlighted> {intl.get('home.our_vision.description.paragraphs.second.sections.second')}</OurVisionSecondParagraph>
                  <OurVisionSecondParagraph> {intl.get('home.our_vision.description.paragraphs.second.sections.third')}</OurVisionSecondParagraph>
                  <OurVisionSecondParagraph highlighted> {intl.get('home.our_vision.description.paragraphs.second.sections.fourth')}</OurVisionSecondParagraph>
                  <OurVisionSecondParagraph> {intl.get('home.our_vision.description.paragraphs.second.sections.fifth')}</OurVisionSecondParagraph>
                  <OurVisionSecondParagraph highlighted> {intl.get('home.our_vision.description.paragraphs.second.sections.sixth')}</OurVisionSecondParagraph>
                </OurVisionSecondParagraphContainer>
                <OurVisionDefaultParagraph>{intl.get('home.our_vision.description.paragraphs.third')}</OurVisionDefaultParagraph>
              </OurSectionRightSideContainer>
            </OurVisionContent>
          </section>
          <section id='our-purpose'>
            <OurPurposeContent>
              <OurSectionLeftSideContainer>
                <OurPurposeTitle>{intl.get('home.purpose.title')}</OurPurposeTitle>
              </OurSectionLeftSideContainer>
              <OurSectionRightSideContainer>
                <OurPurposeFirstParagraph>{intl.get('home.purpose.description.paragraphs.first')}</OurPurposeFirstParagraph>
                <OurPurposeSecondParagraph>{intl.get('home.purpose.description.paragraphs.second')}</OurPurposeSecondParagraph>
                <OurPurposeThirdParagraph>{intl.get('home.purpose.description.paragraphs.third')}</OurPurposeThirdParagraph>
                <OurPurposeThirdParagraph>{intl.get('home.purpose.description.paragraphs.fourth')}</OurPurposeThirdParagraph>
              </OurSectionRightSideContainer>
            </OurPurposeContent>
          </section>
        </OurSectionContainer>
        <PortfolioContainer>
          <PortfolioContent compacted/>
        </PortfolioContainer>
        <section id='where-we-are'>
          <WhereWeAreContainer
            path='home/where-we-are'
            without2x
          >
            {/* <WhereWeAreContent  */}
              <WhereWeAreTitle>{intl.get('home.where_we_are.title')}</WhereWeAreTitle>
              <WhereWeAreSubtitle>{intl.get('home.where_we_are.subtitle')}</WhereWeAreSubtitle>
            {/* </WhereWeAreContent> */}
          </WhereWeAreContainer>
        </section>
        <section id='contact-us'>
          <ContactUsContainer
            path='home/contact-us'
            imageExtension='jpg'
            without2x
          >
            {/* <ContactUsContent> */}
              <span>
                <ContactUsTitle>{intl.get('home.contact_us.title.first')}</ContactUsTitle>
                <ContactUsTitle underline={getLanguage() === 'pt-br' ? true : false}> {intl.get('home.contact_us.title.second')}</ContactUsTitle>
                <ContactUsTitle> {intl.get('home.contact_us.title.third')}</ContactUsTitle>
              </span>
              <ContactUsTitle underline={getLanguage() === 'en-us' ? true : false}>{intl.get('home.contact_us.title.fourth')}</ContactUsTitle>
              <ContactUsSubtitle>{intl.get('home.contact_us.subtitle')}</ContactUsSubtitle>
              <ContactUsButtonsContainer>
                <StartupsContactButton kind='startup'/>
                <StartupsContactButton kind='contact'/>
              </ContactUsButtonsContainer>
            {/* </ContactUsContent> */}
          </ContactUsContainer>
        </section>
        {openVideo && <VideoPlayer onClose={this.toggleVideo}/>}
      </div>
    )
  }
}

export default withRouter(Home)