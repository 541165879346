import React, { Component } from 'react'
import { Modal } from 'components/base'
import videoPT from 'assets/home/who-we-are/video/pt.mp4'
import videoEN from 'assets/home/who-we-are/video/en.mp4'
import ReactPlayer from 'react-player'
import { getLanguage } from 'helpers/languageHelper'

export default class VideoPlayer extends Component {
  ref = player => {
    this.player = player
  }
  
  render() {
    const { onClose } = this.props
    return (              
      <Modal        
        onClose={() => onClose()} 
        width='63%'
        height='80%'
      >
        <ReactPlayer
          ref={this.ref}
          url={getLanguage() === 'pt-br' ? videoPT : videoEN}
          width='100%'
          height='100%'
          playing
          controls          
        />     
      </Modal>         
    )
  }
}






