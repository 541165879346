import React, { Component } from 'react'
import styled from 'styled-components'
import intl from 'react-intl-universal'
import { Modal } from './base'
import { viewports } from 'helpers/settings'


const Container = styled.div`
  display: flex;
`
const Button = styled.a`
  width: 180px;
  height: 45px;
  border: ${props => props.transparent ? '3px solid #00C2FF' : 0};  
  border-radius: 50px;   
  cursor: pointer;
  color: ${props => props.transparent ? '#0599C9' : '#FFFFFF'};
  background-color: ${props => props.transparent ? 'transparent' : props.kind === 'startup' ? '#1F98FF' : '#06cb06'};
  font-size: 18px;
  font-family: Josefin Sans;
  font-weight: bold;
  text-align: center;  
  line-height: 52px;   
  margin: 5px; 
  ${viewports.small} {
    width: 130px;
    font-size: 14px;
    line-height: 52px;   
  }
`
const ContactModalTitle = styled.label`
  font-family: Josefin Sans;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  line-height: 40px;
  color: #0599C9;
  margin-bottom: 30px;
  
  border-bottom: 0.5px solid #606060;  
`
const ContactRowContainer = styled.section`
  line-height: 25px  
`
const ContactModalLabel = styled.label`
  font-family: Lato;
  font-size: 16px;  
  color: #606060  
  font-weight: bold;
  margin-right: 5px;
`
const ContactModalLink = styled.a`
  font-family: Lato;
  font-size: 16px;
  color: #606060  
  text-decoration: none;
  cursor: pointer;

`
export default class StartupsContactButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startupModalOpen: false,
      contactModalOpen: false
    }
  }
  
  renderStartupModal() {        
    return (              
      <Modal        
        onClose={() => this.setState({ startupModalOpen: false })} 
        width='80%'
        height='470px'
      >
        <iframe src="https://superjobs.typeform.com/to/FWSdcy"
          width='100%'
          title="fb-share"
          height='100%'
          style={{border:'none', overflow:'hidden'}}
          scrolling="no"
        />      
      </Modal>         
    )
  }

  renderContactModal() {        
    return (              
      <Modal        
        onClose={() => this.setState({ contactModalOpen: false })}      
        width='600px'
        height='150px'
        smallWidth='76%'
        smallHeight='210px'
      >
        <ContactModalTitle>{intl.get('home.contact_us.modal.title')}</ContactModalTitle>      
        <ContactRowContainer>    
          <ContactModalLabel>{intl.get('home.contact_us.modal.email.title')}:</ContactModalLabel>
          <ContactModalLink href='mailto:sueli.campo@sp4com.com.br'>{intl.get('home.contact_us.modal.email.value')}</ContactModalLink>
        </ContactRowContainer>          
        <ContactRowContainer>    
          <ContactModalLabel>{intl.get('home.contact_us.modal.site.title')}:</ContactModalLabel>
          <ContactModalLink href='http://www.sp4com.com.br' target='_blank'>{intl.get('home.contact_us.modal.site.value')}</ContactModalLink>
        </ContactRowContainer>          
        <ContactRowContainer>    
          <ContactModalLabel>{intl.get('home.contact_us.modal.phone.title')}:</ContactModalLabel>
          <ContactModalLink>{intl.get('home.contact_us.modal.phone.value')}</ContactModalLink>
        </ContactRowContainer>          
      </Modal>         
    )
  }

  onClickButton() {    
    const { kind } = this.props
    if (kind === 'startup') {
      this.setState({ startupModalOpen: true })   
    } else if (kind === 'contact') {
      this.setState({ contactModalOpen: true })   
    }
  }
    
  render() {
    const { startupModalOpen, contactModalOpen } = this.state    
    const { kind, transparent } = this.props
    return (
      <Container>
        <Button           
          transparent={transparent}
          onClick={this.onClickButton.bind(this)}
          kind={kind}
        >          
          {kind === 'startup' ? intl.get('home.startup_button') : intl.get('home.contact_button')}
        </Button>   
        {startupModalOpen && this.renderStartupModal()}
        {contactModalOpen && this.renderContactModal()}
      </Container>
    )
  }
}






