import React, { Component } from 'react'
import { PortfolioContent } from 'components'

export default class Portfolio extends Component {  
  render() {
    return (
      <PortfolioContent/>
    )
  }
}
