import React, { Component } from 'react'
import styled from 'styled-components'
import Collapse from '@material-ui/core/Collapse'
import collapseIcon from 'assets/home/down-arrow.svg'
import intl from 'react-intl-universal'
import { viewports } from 'helpers/settings'
import { StartupsContactButton } from 'components'
import { ImageContainer } from 'components/base'

const TopContainer = styled(ImageContainer)`
  display: flex;
  height: 598px;
  flex-direction: column;
  overflow: hidden;
  background-size: cover;
`
// const TopContainerContent = styled.div`
//   display: flex;
//   background-color: rgba(0, 3, 38, .6);
//   height: 100%;
//   width: 100%;
//   flex-direction: column;
//   overflow: hidden
// `
const SjImageContainer = styled(ImageContainer)`
  height: 130px;
  overflow: hidden;
  margin-top: 90px;
  ${viewports.small} {
    background-size: cover;
  }
`
const SjSloganContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  color: #FFFFFF;
  font-family: Josefin Sans;
  font-size: 48px;
  ${viewports.small} {
    font-size: 23px;
  }
`
const StartupContactContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`
const CollapseButtonContainer = styled.div`
  display: flex;
  flex-grow: 1;
  position: relative;
  align-items: center;
  justify-content: center;
`
const CollapseButton = styled.button`
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 0;
`
const CollapseIcon = styled.img`
  height: 30px;
  width: 30px;
  transform: rotate(${props => props.expanded ? 180 : 0}deg);
  transition: transform .3s, -webkit-transform .3s;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.3s, 0.3s;
    transition-timing-function: ease, ease;
    transition-delay: 0s, 0s;
`

export default class TopCollapsed extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expandPortfolio: false
    }
  }

  onClickCollapseButton = () => {
    const { expandPortfolio } = this.state
    this.setState({ expandPortfolio: !expandPortfolio }, () => !expandPortfolio && window.scrollTo(0, 550))
  }

  render() {
    return (
      <Collapse
        in={true}//Inicialmente abria ao clicar na seta, mas depois foi resolvido ficar fixo
        collapsedHeight='598px'
      >
        <TopContainer
          path='home/mountain'
          imageExtension='jpg'
          without2x
        >
          {/* <TopContainerContent> */}
            <SjImageContainer path='home/sj-logo'/>
            <SjSloganContainer>
              {intl.get('home.slogan')}
            </SjSloganContainer>
            <StartupContactContainer>
              <StartupsContactButton
                transparent
                kind='startup'
              />
            </StartupContactContainer>
            <CollapseButtonContainer>
              <CollapseButton onClick={this.onClickCollapseButton.bind(this)}>
                <CollapseIcon
                  src={collapseIcon}
                  expanded={false}
                />
              </CollapseButton>
            </CollapseButtonContainer>
          {/* </TopContainerContent>              */}
        </TopContainer>
      </Collapse>
    )
  }
}
