import React from 'react'
import styled from 'styled-components'
import intl from 'react-intl-universal'

const Container = styled.div`
  width: 100%;
  height: 40px;
  background-color: #00062A;
  font-family: Josefin Sans;
  font-size: 15px;
  text-align: center;
  line-height: 50px;
  color: #FFFFFF;    
`
const Footer = () => {
  return (
    <Container>{intl.get('footer')}</Container>
  )
}

export default Footer