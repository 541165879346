import React, { Component } from 'react'
import styled from 'styled-components'

const Container = styled.div`  
  background-image: url(${props => props.image});      
  background-repeat: no-repeat;    
  background-position: center;   
`

export default class ImageContainer extends Component {
  loadImages() {
    const { path, imageExtension, without2x } = this.props    
    const imgDefault = require(`../../assets/${path}/default.${imageExtension ? imageExtension : 'png'}`)        
    const img2x = without2x ? null : require(`../../assets/${path}/2x.png`)
    return {
      imgDefault,
      img2x
    }
  }

  render() {
    const { image: imageProps, children, className } = this.props
    const image = imageProps ? imageProps : this.loadImages()
    return (
      <Container className={className} image={imageProps ? image : image.imgDefault} >
        {children}  
      </Container>
    )
  }
}
