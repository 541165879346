export const viewports = {
  big: '@media (min-width: 1280px)',
  base: '@media (max-width: 1279px and min-width: 1025px)',
  medium: '@media (max-width: 1024px and min-width: 681px)',
  small: '@media (max-width: 680px)',
  nano: '@media (max-width: 428px)',  
}

export const responsiveSizes = {
  sidePagePadding: '10px'
}

