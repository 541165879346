import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import Main from './Main'
import intl from 'react-intl-universal'
import { storeLanguage, getLanguage } from 'helpers/languageHelper'
import { Header, Footer } from 'components'
import reset from 'styled-reset'

const GlobalStyles = createGlobalStyle`
  ${reset}
  body {            
    font-size: 16px;    
    margin: 0;
    padding: 0;  
    box-sizing: border-box;      
  }
`
const locales = {
  'pt-br': require('../locales/pt-br.json'),
  'en-us': require('../locales/en-us.json'),
  'zh-hk': require('../locales/zh-hk.json')
}

class App extends Component {

  constructor(props) {
    super(props)    
    const language = getLanguage()   
    const navigatorLanguage = navigator.language.toLowerCase() 
    const currentLocale =  language ? language : locales[navigatorLanguage] ? navigatorLanguage : 'pt-br';    
    if (language !== currentLocale ) {
      storeLanguage(currentLocale)
    }
    intl.init({
      currentLocale,
      locales
    });  
    this.state = {
      scrollPosition: 0
    }
  }   

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (event) => {    
    this.setState({
      scrollPosition: window.pageYOffset
    })      
  } 

  render() {    
    const { scrollPosition } = this.state    
    const mobile = window.document.body.scrollWidth <= 680         
    return (            
      <React.Fragment>   
        <GlobalStyles/>        
        <Header flagsVisible={scrollPosition === 0} fillWhite={this.props.location.pathname === "/" ? scrollPosition < 550 || scrollPosition > (mobile ? 7049 : 3337) : false}/>                
        <Main/>  
        <Footer/>      
      </React.Fragment>
    )
  }
}


export default withRouter(App)
