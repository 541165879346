import React from 'react';
import ReactDOM from 'react-dom';
import { unregister } from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import App from 'containers/App';
import ScrollToTop from 'helpers/scrollToTop';

const Index = () => {
  return(    
    <BrowserRouter>            
      <ScrollToTop>                    
        <App />
      </ScrollToTop>    
    </BrowserRouter>  
  );
}

ReactDOM.render(<Index />, document.getElementById('root'));

unregister();
