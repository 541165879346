import React, { Component } from 'react'
import styled from 'styled-components'
import { MdClose } from 'react-icons/md'
import BaseModal from '@material-ui/core/Modal'
import { viewports } from 'helpers/settings'


const ModalContainer = styled(BaseModal)`
  width: 100%;
  height: 100%; 
`
const ModalContent = styled.div`  
  display: flex;
  flex-direction: column;  
  border-radius: 4px;
  padding: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  width: ${props => props.width}; 
  height: ${props => props.height}; 
  transform: translate(-50%, -50%);     
  background-color: #FFFFFF;  
  outline: 0;
  ${viewports.small} {
    ${props => props.smallWidth && `width: ${props.smallWidth}`}
    ${props => props.smallHeight && `height: ${props.smallHeight}`}
  }       

`
const CloseIcon = styled(MdClose)`
  width: 100%;
  height: 100%;
`
const CloseButton = styled.div`  
  color: black;
  cursor: pointer;
  font-size: 28px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 28px;
  height: 28px;
  ${viewports.small} {
    font-size: 15px;
  }
`

export default class Modal extends Component {    
  render() {
    const { children, onClose, width, height, smallWidth, smallHeight } = this.props
    return (              
      <ModalContainer
        open={true}
        disableBackdropClick
        disableEscapeKeyDown={false} 
        onClose={onClose.bind(this)}
      >
        <ModalContent
          width={width}
          height={height}
          smallWidth={smallWidth}
          smallHeight={smallHeight}
        >  
          <CloseButton onClick={onClose.bind(this)}> 
            <CloseIcon/>       
          </CloseButton>
          {children}
        </ModalContent>
      </ModalContainer>          
    )
  }
}






