import React from 'react'
import styled from 'styled-components'
import { viewports, responsiveSizes } from 'helpers/settings'
import { ImageContainer } from './base'
import intl from 'react-intl-universal'
import { MdAdd } from 'react-icons/md'
import { HashLink as Link } from 'react-router-hash-link'
import { Desktop, Mobile } from 'helpers/responsive'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const NationalContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

`
const InternationalContainer = styled(ImageContainer)`
  display: flex;
  flex-direction: column;
  background-size: cover;
  padding-top: 180px;
  text-align: center;
  height: 1770px;
  padding-bottom: 50px;
  ${viewports.small} {
    padding-top: 0;
    height: auto;
    background-position-y: -125px;
  }
`
const NationalTitle = styled.label`
  align-items: center;
  justify-content: center;
  ${props => !props.alignCenter && 'text-align: left'}};
  color: #000000;
  font-family: Josefin Sans;
  font-size: 40px;
  margin: 60px;
  text-transform: capitalize;
  ${viewports.small} {
    font-size: 23px;
    margin-top: 110px;
  }
`
const InternationalTitle = styled.label`
  align-items: center;
  justify-content: center;
  color: #000000;
  font-family: Josefin Sans;
  font-size: 40px;
  font-weight: bold;
  margin: 60px;
  text-transform: capitalize;
  ${viewports.small} {
    font-size: 23px;
    margin-top: 100px;
  }
`
const CompaniesContainer = styled.div`
  display: flex;
  margin-top: 60px;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  ${viewports.small} {
    flex-direction: column;
    justify-content: center;
    padding-right: ${responsiveSizes.sidePagePadding};
    padding-left: ${responsiveSizes.sidePagePadding}
  }
  ${viewports.medium} {
    padding-right: 210px;
  }
`
const ColumnCompanies = styled.div`
  display: flex;
  height: 100%;

  flex-direction: column;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  overflow: hidden;
  ${viewports.small} {
    padding: 0;
    width: 100%;
    margin-bottom: 30px;
  }
  ${viewports.big} {
    width: 300px;
  }
`
const ColumnTitle = styled.label`
  font-family: Josefin Sans;
  font-size: 30px;
  color: #000000;
  border-bottom: 1px solid #000000;
  opacity: 0.7;
  margin-bottom: 30px;
`
const LogoCompanyContainer = styled(ImageContainer)`
  height: 150px;
  background-size: ${props => props.backgroundSize ? props.backgroundSize : '60%'};
  &:hover {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: opacity .35s,-webkit-transform .35s;
    transition: opacity .35s,transform .35s;
  }
  ${viewports.small} {
    height: 155px;
    background-size: ${props => props.backgroundSize ? props.backgroundSize : '50%'};
  }

`
const CompanyDescription = styled.label`
  font-size: 16px;
  font-family: Lato;
  color: #000000;
  margin-bottom: 20px;
  ${viewports.small} {
    text-align: justify;
    width: 300px;
  }
`
const BabelLogo = styled(ImageContainer)`
  height: 132px;
  background-size: 18%;
  ${viewports.small} {
    background-size: 30%;
  }
`
const CompanyWebLink = styled.a`
  width: 100%;
  cursor: pointer;
  border-bottom: ${props => props.international && '2px solid #DFDFDF'};
  margin-bottom: ${props => props.international && '30px'};;
  text-decoration: none;

`

const LinkToPortfolioPage = styled(Link)`
  width: 400px;
  font-size: 24px;
  font-family: Josefin Sans;
  font-weight: bold;
  color: #000000;
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
  align-self: center;
  text-decoration: none;
  margin-bottom: 20px;
  ${viewports.small} {
    font-size: 16px;
  }
`
const LinkToPortfolioPageIcon = styled(MdAdd)`
  font-size: 34px;
  position: relative;
  top: 7px;
  ${viewports.small} {
    top: 10px
  }
`
const LinkToCategory = styled(Link)`
  font-size: 16px;
  width: 400px;
  font-family: Josefin Sans;
  font-weight: bold;
  color: #000000;
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
  align-self: center;
  text-decoration: none;
  margin-bottom: 20px;
  line-height: 30px;
`

const fintechNationalPath = (company) => `portfolio/fintech/national/${company}`

const impactNationalPath = (company) => `portfolio/impact/national/${company}`

const healthNationalPath = (company) => `portfolio/health/national/${company}`

const fintechInternationalPath = (company) => `portfolio/fintech/international/${company}`

const impactInternationalPath = (company) => `portfolio/impact/international/${company}`

const healthInternationalPath = (company) => `portfolio/health/international/${company}`

const renderCompanyCard = (link, title, imagePath, imageExtension, without2x, international, size) => {
  return (
    <CompanyWebLink
      href={link}
      title={title}
      target='_blank'
      international={international}
    >
      <LogoCompanyContainer
        path={imagePath}
        imageExtension={imageExtension}
        without2x={without2x}
        backgroundSize={size}
      />
    </CompanyWebLink>
  )
}

const renderNationalCompanyCard = (link, title, imagePath, imageExtension, without2x, size) =>
  renderCompanyCard(link, title, imagePath, imageExtension, without2x, false, size)

const renderInternationalCompanyCard = (link, title, imagePath, imageExtension, without2x, size)  =>
  renderCompanyCard(link, title, imagePath, imageExtension, without2x, true, size)

const PortfolioContent = (props) => {
  const nationalCompaniesTranslator = 'portfolio.national.companies'
  const internationalCompaniesTranslator = 'portfolio.international.companies'
  return (
    <Container>
      <NationalContainer>
        <NationalTitle alignCenter={props.compacted}>
          {intl.get('portfolio.national.title')}
        </NationalTitle>
        <CompaniesContainer>
          <section id='fintech'>
            <ColumnCompanies compacted={props.compacted}>
              <ColumnTitle>{intl.get(`${nationalCompaniesTranslator}.fintech`)}</ColumnTitle>
              {renderNationalCompanyCard('https://www.supercambio.com.br/', 'Super Câmbio', fintechNationalPath('super-cambio'))}
              {renderNationalCompanyCard('https://www.firgun.com.br/', 'Firgun', fintechNationalPath('firgun'))}
              {renderNationalCompanyCard('https://www.drcash.com.br/', 'Dr. Cash', fintechNationalPath('dr-credit'))}
              {
                props.compacted &&
                <Mobile>
                  <LinkToCategory to={'/portfolio#fintech'}><LinkToPortfolioPageIcon/>{intl.get('portfolio.link_to_category')}</LinkToCategory>
                </Mobile>
              }
              {
                !props.compacted &&
                [
                  renderNationalCompanyCard('https://wuzu.io/', 'Wuzu', fintechNationalPath('wuzu')),
                  renderNationalCompanyCard('https://www.startmeup.com.br/', 'Start Me Up', fintechNationalPath('start-me-up'), 'jpeg', true),
                  renderNationalCompanyCard('https://www.recb.com.br/', 'Recb', fintechNationalPath('recb')),
                  renderNationalCompanyCard('https://www.paguebembrasil.com.br/', 'Pague Bem Brasil', fintechNationalPath('pague-bem-brasil'), 'svg', true),
                ]
              }
            </ColumnCompanies>
          </section>
          <section id='impact'>
            <ColumnCompanies compacted={props.compacted}>
              <ColumnTitle>{intl.get(`${nationalCompaniesTranslator}.impact`)}</ColumnTitle>
              {renderNationalCompanyCard('http://www.deixacommarta.com.br/', 'Marta', impactNationalPath('marta'), 'png', true)}
              {renderNationalCompanyCard('https://www.methaenergia.com.br/', 'Metha Energia', impactNationalPath('metha'), 'png', true)}
              {renderNationalCompanyCard('http://matchboxbrasil.com/', 'MatchBox', impactNationalPath('matchbox'), 'png', true)}

              {
                props.compacted &&
                <Mobile>
                  <LinkToCategory to={'/portfolio#impact'}><LinkToPortfolioPageIcon/>{intl.get('portfolio.link_to_category')}</LinkToCategory>
                </Mobile>
              }
              {
                !props.compacted &&
                [
                  renderNationalCompanyCard('https://www.apptite.com/', 'Apptite', impactNationalPath('apptite'), 'png', true),
                  renderNationalCompanyCard('https://www.contraktor.com.br/', 'Contraktor', impactNationalPath('contraktor'), 'jpg', true),
                  renderNationalCompanyCard('http://www.vibbra.com.br/', 'Vibbra', impactNationalPath('vibbra')),
                  renderNationalCompanyCard('https://zaply.com.br/', 'Zapply', impactNationalPath('zaply')),
                  renderNationalCompanyCard('https://www.rheco.me/', 'Rheco.me', impactNationalPath('rheco.me'))
                ]
              }
            </ColumnCompanies>
          </section>
          <section id='health'>
            <ColumnCompanies compacted={props.compacted}>
              <ColumnTitle>{intl.get(`${nationalCompaniesTranslator}.health`)}</ColumnTitle>
              {renderNationalCompanyCard('https://elyment.com.br/', 'Elyment', healthNationalPath('elyment'), 'jpg', true)}
              {renderNationalCompanyCard('https://www.vittude.com', 'Vittude', healthNationalPath('vittude'))}
              {renderNationalCompanyCard('https://drcannabis.com.br', 'Dr Cannabis', healthNationalPath('dr-cannabis'))}
              {
                props.compacted &&
                <Mobile>
                  <LinkToCategory to={'/portfolio#health'}><LinkToPortfolioPageIcon/>{intl.get('portfolio.link_to_category')}</LinkToCategory>
                </Mobile>
              }
              {/* {
                !props.compacted &&
                [
                  renderNationalCompanyCard('https://drsintomas.com.br/', 'Dr Sintomas', healthNationalPath('dr-sintomas')),
                  renderNationalCompanyCard('http://www.familydoc.com.br/', 'Family Doc', healthNationalPath('family-doc'), 'jpg', true)
                ]
              } */}
            </ColumnCompanies>
          </section>
        </CompaniesContainer>
        {
          props.compacted &&
          <Desktop>
            <LinkToPortfolioPage to='/portfolio'><LinkToPortfolioPageIcon/>{intl.get('portfolio.link_to_portfolio_page')}</LinkToPortfolioPage>
          </Desktop>
        }
      </NationalContainer>
      <InternationalContainer
        path='portfolio/international-background'
        compacted={props.compacted}
      >
        <InternationalTitle>
          {intl.get('portfolio.international.title')}
        </InternationalTitle>
        <BabelLogo
          path='portfolio/babel-logo'
          without2x
        />
        <CompaniesContainer>
          <ColumnCompanies>
            {renderInternationalCompanyCard('https://www.cuehealth.com/', 'Cue', fintechInternationalPath('babel-cue'), '', true, '25%')}
            <CompanyDescription>
              {intl.get(`${internationalCompaniesTranslator}.fintech.cue`)}
            </CompanyDescription>
            {renderInternationalCompanyCard('https://foreverlabs.com/', 'Forever Labs', fintechInternationalPath('babel-forever-labs'))}
            <CompanyDescription>
              {intl.get(`${internationalCompaniesTranslator}.fintech.forever_labs`)}
            </CompanyDescription>
            {renderInternationalCompanyCard('https://www.cannaly.co/', 'Cannaly', fintechInternationalPath('babel-cannaly'))}
            <CompanyDescription>
              {intl.get(`${internationalCompaniesTranslator}.fintech.cannaly`)}
            </CompanyDescription>
          </ColumnCompanies>
          <ColumnCompanies>
          {renderInternationalCompanyCard('https://nebia.com/', 'Nebia', impactInternationalPath('babel-nebia'))}
            <CompanyDescription>
              {intl.get(`${internationalCompaniesTranslator}.impact.nebia`)}
            </CompanyDescription>
            {renderInternationalCompanyCard('https://vitagene.com/', 'Vitagene', impactInternationalPath('babel-vitagene'), '', true, '55%')}
            <CompanyDescription>
              {intl.get(`${internationalCompaniesTranslator}.impact.vitagene`)}
            </CompanyDescription>
            {renderInternationalCompanyCard('https://wildearth.com/', 'Wild Earth', impactInternationalPath('babel-wild-earth'))}
            <CompanyDescription>
              {intl.get(`${internationalCompaniesTranslator}.impact.wild-earth`)}
            </CompanyDescription>
            {renderInternationalCompanyCard('https://zbiotics.com/', 'ZBiotics', impactInternationalPath('babel-zbiotics'))}
            <CompanyDescription>
              {intl.get(`${internationalCompaniesTranslator}.impact.zbiotics`)}
            </CompanyDescription>
          </ColumnCompanies>
          <ColumnCompanies>
            {renderInternationalCompanyCard('https://finlessfoods.com/', 'Finless Food', healthInternationalPath('babel-finless-foods'), '', true, '38%')}
            <CompanyDescription>
              {intl.get(`${internationalCompaniesTranslator}.health.finless-foods`)}
            </CompanyDescription>
            {renderInternationalCompanyCard('https://www.occamzrazor.com/', 'Razor', healthInternationalPath('babel-occamz-razor'))}
            <CompanyDescription>
              {intl.get(`${internationalCompaniesTranslator}.health.occamz_razor`)}
            </CompanyDescription>
            {renderInternationalCompanyCard('https://www.nabis.com/', 'Nabis', healthInternationalPath('babel-nabis'))}
            <CompanyDescription>
              {intl.get(`${internationalCompaniesTranslator}.health.nabis`)}
            </CompanyDescription>
            {renderInternationalCompanyCard('https://drinkdreamin.com/', 'California Dreamin', healthInternationalPath('babel-california-dreamin'), '', true, '35%')}
            <CompanyDescription>
              {intl.get(`${internationalCompaniesTranslator}.health.california_dreamin`)}
            </CompanyDescription>
          </ColumnCompanies>
        </CompaniesContainer>
      </InternationalContainer>
    </Container>
  )
}

export default PortfolioContent