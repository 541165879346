import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import styled from 'styled-components'
import brazil from 'assets/header/flags/brazil/default.png'
import unitedStates from 'assets/header/flags/united-states/default.png'
import china from 'assets/header/flags/china/default.png'
import { GiHamburgerMenu } from 'react-icons/gi'
import { MdClose } from 'react-icons/md'
import Drawer from '@material-ui/core/Drawer'
import intl from 'react-intl-universal'
import { storeLanguage } from 'helpers/languageHelper'
import { viewports } from 'helpers/settings'


const Container = styled.header`
  display: flex;
  background-color: transparent;
  height: 50px;
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 1;
`
const RightButtonsContainer = styled.div`
  flex-grow: 1;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 40px;
`
const RightButton = styled.div`
  display: ${props => props.hidden? 'hidden' : 'flex'};
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border-radius: 3px;
  border: 2px solid transparent;
  margin-right: 8px;
  overflow: hidden;
  cursor: pointer;
  float: right;
  &:hover {]
    border: ${props => props.withHover && '2px solid #FFF'};
  }
  ${viewports.small} {
    height: 30px;
    width: 30px;
  }
`
const FlagIcon = styled.img`
  position: relative;
  top: 1.4px;
  width: 100%;
  height: 100%;
  ${viewports.small} {
    width: 30px;
    height: 30px;
  }
`
const BurgerIcon = styled(GiHamburgerMenu)`
  width: 100%;
  height: 100%;
  fill: ${props => props.fillWhite ? '#FFFFFF' : '#696969'};
`
const MenuContainer = styled.div`
  display: flex;
  width: 300px;
  height: 100%;
  flex-direction: column;
  padding: 20px;
  ${viewports.small} {
    width: 200px;
  }
`
const MenuTopContainer = styled.div`
  width: 100%;
`
const CloseMenuIcon = styled(MdClose)`
  width: 100%;
  height: 100%;
`
const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`
const MenuItem = styled.div`
  display: flex;
  text-align: center;
`

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openMenu: false
    }
  }

  toggleMenu() {
    const { openMenu } = this.state
    this.setState({ openMenu: !openMenu })
  }

  renderMenuItem(link, translatorAttr, changePage) {
    const ChangePageLink = styled(Link)`
      width: 100%;
      height: 100%;
      text-align: center;
      font-family: Lato;
      font-size: 16px;
      letter-spacing: 2px;
      color: #000000;
      line-height: 50px;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -o-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
        -webkit-transition: opacity .35s,-webkit-transform .35s;
        transition: opacity .35s,transform .35s;
      }
    `
    const ChangePageA = styled.a`
      width: 100%;
      height: 100%;
      text-align: center;
      font-family: Lato;
      font-size: 14px;
      letter-spacing: 2px;
      color: #000000;
      line-height: 50px;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -o-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
        -webkit-transition: opacity .35s,-webkit-transform .35s;
        transition: opacity .35s,transform .35s;
      }
    `

    return(
      <MenuItem
        onClick={this.toggleMenu.bind(this)}
      >
        {
          changePage ?
            <ChangePageLink to={link}>{intl.get(`${translatorAttr}`)}</ChangePageLink>
          :
            <ChangePageA href={link}>{intl.get(`${translatorAttr}`)}</ChangePageA>
        }
      </MenuItem>
    )
  }

  onClickFlagButton(language) {
    storeLanguage(language)
    window.location.reload()
  }


  render() {
    const { openMenu } = this.state
    const { fillWhite, flagsVisible } = this.props
    return (
      <Container>
        <RightButtonsContainer>
          <RightButton
            withHover
            onClick={() => this.onClickFlagButton('pt-br')}
            hidden={!flagsVisible}
          >
            <FlagIcon src={brazil}/>
          </RightButton>
          <RightButton
            withHover
            onClick={() => this.onClickFlagButton('en-us')}
            hidden={!flagsVisible}
          >
            <FlagIcon src={unitedStates}/>
          </RightButton>
          <RightButton
            withHover
            onClick={() => this.onClickFlagButton('zh-hk')}
            hidden={!flagsVisible}
          >
            <FlagIcon src={china}/>
          </RightButton>
          <RightButton onClick={this.toggleMenu.bind(this)}>
            <BurgerIcon fillWhite={fillWhite}/>
          </RightButton>
        </RightButtonsContainer>
        {
          openMenu &&
          <Drawer
            anchor='right'
            open={openMenu}
            onClose={this.toggleMenu.bind(this)}>
            <MenuContainer>
              <MenuTopContainer>
                <RightButton onClick={this.toggleMenu.bind(this)}>
                  <CloseMenuIcon/>
                </RightButton>
              </MenuTopContainer>
              <MenuItemContainer>
                {this.renderMenuItem('/', 'menu.home', true)}
                {this.renderMenuItem('/#who-we-are', 'menu.who_we_are')}
                {this.renderMenuItem('/#our-vision', 'menu.our_vision')}
                {this.renderMenuItem('/#our-purpose', 'menu.our_purpose')}
                {this.renderMenuItem('/#where-we-are', 'menu.where_we_are')}
                {this.renderMenuItem('/portfolio', 'menu.portfolio', true)}
                {this.renderMenuItem('/team', 'menu.our_team', true)}
                {this.renderMenuItem('/#contact-us', 'menu.contact')}
              </MenuItemContainer>
            </MenuContainer>
          </Drawer>
        }
      </Container>
    )
  }
}

export default withRouter(Header)