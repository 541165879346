import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { 
  Home,
  Portfolio,
  Team
} from './pages'


const Main = () => {    
  return(
    <Route render={({ location }) => (
      <Switch location={location} >
        <Route exact path="/" component={Home}/>
        <Route path="/portfolio" component={Portfolio}/>
        <Route path="/team" component={Team}/>
      </Switch>
    )}
    />
  )
}

export default Main